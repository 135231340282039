<template>
  <div class="row justify-content-center">
    <div class="form-group col-3">
      <label for>Present Address</label>
      <fg-input
        type="text"
        name="present_address_line"
        placeholder="Present Address"
        v-model="model.present_address_line"
        v-validate="modelValidations.present_address_line"
        :error="getError('present_address_line')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Present State</label>
      <fg-input
        type="text"
        name="present_state"
        placeholder="Present State"
        v-model="model.present_state"
        v-validate="modelValidations.present_state"
        :error="getError('present_state')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Present Pincode</label>
      <fg-input
        type="number"
        name="present_pin"
        placeholder="Present Pincode"
        v-model="model.present_pin"
        v-validate="modelValidations.present_pin"
        :error="getError('present_pin')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Present Country</label>
      <fg-input
        type="text"
        name="present_country"
        placeholder="Present Country"
        v-model="model.present_country"
        v-validate="modelValidations.present_country"
        :error="getError('present_country')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Permanent Address</label>
      <fg-input
        type="text"
        name="permanent_address_line"
        placeholder="Permanent Address"
        v-model="model.permanent_address_line"
        v-validate="modelValidations.permanent_address_line"
        :error="getError('permanent_address_line')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Permanent State</label>
      <fg-input
        type="text"
        name="permanent_state"
        placeholder="Permanent State"
        v-model="model.permanent_state"
        v-validate="modelValidations.permanent_state"
        :error="getError('permanent_state')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Permanent Pincode</label>
      <fg-input
        type="number"
        name="permanent_pin"
        placeholder="Permanent Pincode"
        v-model="model.permanent_pin"
        v-validate="modelValidations.permanent_pin"
        :error="getError('permanent_pin')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Permanent Country</label>
      <fg-input
        type="text"
        name="permanent_country"
        placeholder="Permanent Country"
        v-model="model.permanent_country"
        v-validate="modelValidations.permanent_country"
        :error="getError('permanent_country')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Adhaar Number</label>
      <fg-input
        type="text"
        name="adhaar_no"
        placeholder="Adhaar Number"
        v-model="model.adhaar_no"
        v-validate="modelValidations.adhaar_no"
        :error="getError('adhaar_no')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>PAN Number</label>
      <fg-input
        type="text"
        name="pan_no"
        placeholder="PAN Number"
        v-model="model.pan_no"
        v-validate="modelValidations.pan_no"
        :error="getError('pan_no')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Bank Name</label>
      <fg-input
        type="text"
        name="bank_name"
        placeholder="Bank Name"
        v-model="model.bank_name"
        v-validate="modelValidations.bank_name"
        :error="getError('bank_name')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Bank Branch Name</label>
      <fg-input
        type="text"
        name="bank_branch_name"
        placeholder="Bank Branch Name"
        v-model="model.bank_branch_name"
        v-validate="modelValidations.bank_branch_name"
        :error="getError('bank_branch_name')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Bank A/C No</label>
      <fg-input
        type="text"
        name="bank_acc_no"
        placeholder="Bank A/C No"
        v-model="model.bank_acc_no"
        v-validate="modelValidations.bank_acc_no"
        :error="getError('bank_acc_no')"
      ></fg-input>
    </div>

    <div class="form-group col-3">
      <label for>Bank IFSC</label>
      <fg-input
        type="text"
        name="bank_ifsc_code"
        placeholder="Bank IFSC"
        v-model="model.bank_ifsc_code"
        v-validate="modelValidations.bank_ifsc_code"
        :error="getError('bank_ifsc_code')"
      ></fg-input>
    </div>
  </div>
</template>
<script>
import { Select, Option } from "element-ui";
export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option
  },
  data() {
    return {
      model: {
        present_address_line: "",
        present_state: "",
        present_pin: "",
        present_country: "",
        permanent_address_line: "",
        permanent_state: "",
        permanent_pin: "",
        permanent_country: "",
        adhaar_no: "",
        pan_no: "",
        bank_name: "",
        bank_branch_name: "",
        bank_acc_no: "",
        bank_ifsc_code: ""
      },
      countryOptions: ["India", "Other"],
      modelValidations: {
        present_address_line: {
          required: true
        },
        present_state: {
          required: true
        },
        present_pin: {
          required: true,
          length: 6
        },
        present_country: {
          required: true
        },
        permanent_address_line: {
          required: true
        },
        permanent_state: {
          required: true
        },
        permanent_pin: {
          required: true,
          length: 6
        },
        permanent_country: {
          required: true
        },
        adhaar_no: {
          required: true,
          length: 12
        },
        pan_no: {
          required: true,
          length: 10
        },
        bank_name: {
          required: true
        },
        bank_branch_name: {
          required: true
        },
        bank_acc_no: {
          required: true,
          max: 16,
          min: 12
        },
        bank_ifsc_code: {
          required: true,
          length: 11
        }
      }
    };
  },
  methods: {
    getError(fieldName) {
      return this.errors.first(fieldName);
    },
    validate() {
      return this.$validator.validateAll().then(res => {
        this.$emit("on-validated", res, this.model);
        return res;
      });
    }
  }
};
</script>
<style>
</style>
